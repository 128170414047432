<template>
  <div class="edu-frame">
    <edu-nav-bar title="活动记录" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="(item,index) in list" :key="index" class="class-list">
          <div class="edu-group class-item" style="flex-direction: row;display: flex;">
            <div class="class-detail">
              <div class="class-detail-title">活动名称：{{ item.activityName }}</div>
              <div class="class-detail-more">报名时间：{{ item.buyTime }}</div>
              <div class="class-detail-more">
                <div class="class-detail-row">
                  <div class="class-detail-row-title">志愿者名称：{{ item.fullName }}</div>
                  <div class="class-detail-row-title">联系电话：{{ item.custCode }}</div>
                </div>
                <div class="class-detail-row">
                  <div class="class-detail-row-title">活动开始时间：{{ item.actBeginTime }}</div>
                </div>
                <div class="class-detail-row">
                  <div class="class-detail-row-title">活动结束时间：{{ item.actEndTime }}</div>
                </div>
              </div>

<!--              <div style="background-color: #f0f0f0;padding: 5px;">
                <div style="font-weight: bold;">填写资料：</div>
                <div class="class-detail-more" v-for="(detail, dindex) in item.settingdetails" :key="'d_'+dindex">
                  <div class="class-detail-row-title">{{detail.itemName}}：{{ detail.answer }}</div>
                </div>
              </div>-->

            </div>

          </div>
          <div style="width: 100%;display: flex;justify-content: flex-end;">
            <van-button style="margin-right: 10px;background-color: orange;color: white;font-weight: bold;" square size="small" type="default" @click="writeExperience(item)">
              <div v-if="item.isSubmitComment == 1">查看心得</div>
              <div v-else>填写心得</div>
            </van-button>
            <van-button v-if="item.canCancel == 1" style="margin-right: 10px;background-color: red;color: white;font-weight: bold;" square size="small" type="default" @click="cancelActivity(item)">取消报名</van-button>
          </div>
        </div>
        <hr class="class-split"/>
      </van-list>

    </div>

  </div>
</template>
<script>
/*Checkbox*/
import {Button, List} from "vant";
import EduNavBar from "@/components/VolunteerEduNavBar";
import ActivityApi from "@/api/ActivityApi";
import Tools from "@/api/Tools";
import EduConstants from '@/api/EduConstants';
import XEUtils from 'xe-utils'

export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanList: List
    /*VanCheckbox: Checkbox*/
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
      LOGINTYPE_OLD: EduConstants.LOGINTYPE_OLD,
      show: false,
      list: [],
      payTotal: 0,
      reportData: '',
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageInfo.condition.custId = this.cust.custId
      ActivityApi.getActivityBuysRptByCust(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page + 1
        }
      }).catch(() => { this.loading = false;this.finished = true })
    },
    writeExperience(item) {
      this.$router.push({ name: 'vhdgledit',query: { activityBuyMasterId: item.activityBuyMasterId } })
    },
    cancelActivity(item) {
      ActivityApi.cancelBuyActivity({ activityBuyMasterId: item.activityBuyMasterId }).then(response => {
        this.$dialog.alert({message: response.msg})
        if (response.code == 100) {
          this.onLoad()
        }
      })
    }
  },
  mounted() {
    // this.getAlreadyBaoduClasses()
  }
}
</script>
<style scoped>
.class-list {
  width: 95%;
  border-radius: 8px;
  border: 1px solid lightgrey;
  box-shadow: 1px 2px 2px #ddd;
  margin: 10px auto;
  padding: 8px 0px;
  background-color: #cde7f7;
}

.title-tips {
  margin-left: 2%;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: black;
}

.class-split {
  width: 95%;
  height: 1px;
  border-top: 2px dotted lightgrey;
}
.pop-btn {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #1989fa;
  padding: 3px 8px;
  font-size: 14px;
  color: white;
  border-left: 2px solid lightblue;
  border-top: 2px solid lightblue;
  border-bottom: 2px solid lightblue;
  z-index: 999;
}

.content-frame {
  height: calc(100vh - 46px);
}

.activity-list {
  width: 100%;
  overflow: auto;
}
</style>
